import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Row, Col, Tooltip } from "reactstrap"
import AddClient from "../../pages/Myclients/addClient";
import AddUser from "../../pages/Users/addUser";

const BreadcrumbManual = (prop) => {
  const {getUserData, breadcrumbItem, getClientsData, type, usersCount, clientType, setClientType} = prop
  const [openPopup, setOpenPopup] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  
  // toggle add new account modal
  const toggleModel = () => {
    setOpenPopup(!openPopup);
  }
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
          <div className="mb-0">
              <>
                <span onMouseOver={() => {type === 'user' && usersCount.current_users >= usersCount.max_users ? setTooltipOpen(true) : setTooltipOpen(false)}} id="TooltipExample">
                {  clientType == 'archieve' ?
                    <button
                      type="button"
                      className="btn btn-lbusers me-2"
                      // disabled={type === 'user' && (usersCount.current_users >= usersCount.max_users)}
                      onClick={(e) => {
                        setClientType('active');
                      }}
                    >
                      VIEW ACTIVE
                    </button>
                  :
                  <>
                    {type != 'user' && <button
                      type="button"
                      className="btn btn-lbusers me-2"
                      disabled={type === 'user' && (usersCount.current_users >= usersCount.max_users)}
                      onClick={(e) => {
                        setClientType('archieve');
                      }}
                    >
                      VIEW ARCHIEVED
                    </button> }                 
                    <button
                      type="button"
                      className="btn btn-lbusers"
                      disabled={type === 'user' && (usersCount.current_users >= usersCount.max_users)}
                      onClick={(e) => {
                        toggleModel();
                      }}
                    >
                      ADD NEW
                    </button>
                  </>
                }
                </span>
                <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" style={{backgroundColor: '#005691'}} toggle={() => setTooltipOpen(false)}>
                  Users limit reached
                </Tooltip>
              </>
            {
              prop.type === 'client' ?
                <AddClient addOpen={openPopup} toggleAdd={toggleModel} setOpenPopup={setOpenPopup} getClientsData={getClientsData} />
              : prop.type === 'user' ?
                <AddUser addOpen={openPopup} toggleAdd={toggleModel} setOpenPopup={setOpenPopup} getUserData={getUserData} />
              :""
            }
          </div>
        </div>
      </Col>
    </Row>
  )
}

BreadcrumbManual.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default BreadcrumbManual
