import React,{useState} from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';

const DeleteUser = (prop) => {
    const {deleteOpen, toggleDelete, setDeleteOpen, client, getClientsData, setSelectedClient} = prop;
    const [loading, setLoading] = useState(false);

    const handleDeleteClient = async(e) => {
        setLoading(true)
        try {
            e.preventDefault()
            const {data} = await axios.post(`${AppConfig.baseUrl}/client/delete_client`,{client_id: client._id}, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('Client deleted successfully');
                setDeleteOpen(false);
                getClientsData();
                setSelectedClient({});
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            setDeleteOpen(false);
            setSelectedClient({});
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    return (
        <Modal
            isOpen={deleteOpen}
            toggle={() => {
                toggleDelete();
                setSelectedClient({});
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Delete Client</h5>
                <button
                    type="button"
                    onClick={() => {
                        setDeleteOpen(false);
                        setSelectedClient({});
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleDeleteClient}>
                    <div className="mb-3">
                        Are you sure you want to delete this client ?
                    </div>
                    <div className="modal-footer mt-3">
                        <button
                            type="button"
                            className="btn btn-lbusers"
                            onClick={() =>{
                                setDeleteOpen(false)
                                setSelectedClient({})
                            }}
                            disabled={loading} 
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            No
                        </button>
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            Yes
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default DeleteUser