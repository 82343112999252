import React,{useContext} from "react";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logoSmall from "../../assets/images/logos/logo-small.png";
import { AuthContext } from "context/authContext";
import { UncontrolledAlert } from 'reactstrap';

const Header = props => {
  const { userData } = useContext(AuthContext)
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          {
            (userData.is_expiration === true || userData.payment_overdue === true) && 
            <div style={{width:'100%'}} className="mt-3">
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
              {
                userData.is_expiration ? 
                <div><i className="mdi mdi-alert-outline me-2"></i>CREDIT CARD EXPIRATION: Your payment method is expiring soon. We will not be able to process your next invoice, unless you update your payment method.</div>
                  : ''
              }
              {
                userData.payment_overdue ? 
                <div><i className="mdi mdi-alert-outline me-2"></i>Your payment for LeaseJava is overdue, please make the payment as soon as possible to avoid potential disruption to your service.</div>
                  :''
              }
              </UncontrolledAlert>
            </div>
          }
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header
