import React, { useEffect, useState } from "react"
import { PieChart } from '@mui/x-charts/PieChart';

const Piechart = (prop) => {
  const {leaseSummary} = prop;
  const [pieChartData,setPieChartData] = useState([]);
  const [colors,setColors] = useState([]);

  useEffect(() => {
    if(leaseSummary){
      var array1 = [];
      const colorsArray = [];
      leaseSummary.map((i) => {
        array1.push({label:(i.clientName),value:(i.activeLeases + i.terminatedLeases + i.expiredLeases + i.draftedLeases)}) 
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        colorsArray.push(randomColor);
      });
      setPieChartData(array1);
      setColors(colorsArray)
    }
}, [leaseSummary])
  
  return (
    <React.Fragment>
      <PieChart
        colors={colors}
        series={[
          {
            data: pieChartData,
            innerRadius: 0,
            outerRadius: 90,
            paddingAngle: 0,
            cornerRadius: 0,
            startAngle: 0,
            endAngle: 360,
            cx: 90,
            cy: 110,
          },
        ]}
        height={220}
        slotProps={{legend: {markGap: 1, itemMarkHeight:10, itemMarkWidth: 10, labelStyle:{fontSize:12}}}}
      />
    </React.Fragment>
  )
}
export default Piechart
