import React from "react"
import PropTypes from 'prop-types'
import { Row, Col } from "reactstrap"


const BreadcrumbClientDetail = (prop) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-sm-flex align-items-center">
            {
              prop.type === 'reports_filter' ? ''
                : prop.type === 'reports' ? ''
                    : prop.image ?
                      <img
                        className="rounded-circle avatar-sm"
                        src={prop.image }
                        alt=""
                      />
                    :
                    <div className="avatar-sm">
                      <div className="avatar-title rounded-circle">
                        {prop && prop.name && prop.name.charAt(0)}
                      </div>
                    </div>
            }&nbsp;
            {prop.type === 'reports' ?
              <>
                <h4 className="mb-0 font-size-18">{prop.name1}</h4>
                <button
                  type="button"
                  className="btn btn-lbusers"
                  style={{ visibility: 'hidden' }}
                >
                  ADD NEW
                </button>
              </>
              :
              <h4 className="mb-0 font-size-18 ms-2">{prop.name} {prop.breadcrumbItem}</h4>
            }
          </div>

          {prop.type === 'reports_filter' ?
            <>
              <button
                type="button"
                className="btn btn-lbusers"
                style={{ visibility: 'hidden' }}
              >
                ADD NEW
              </button>
            </>
            : ''
          }
        </div>
      </Col>
    </Row>
  )
}
// {user.client}-

BreadcrumbClientDetail.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default BreadcrumbClientDetail
