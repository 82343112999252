import React, { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';

const ButtonDropdownClient = (prop) => {
  const { setEditOpen, setDeleteOpen, setSelectedUser,setSelectedClient, client, user, type, handleRestoreClient } = prop;
  const [viewBtn, setViewBtn] = useState(false);
  return (
    <div className="btn-group mb-2 dropstart">
      <ButtonDropdown
        isOpen={viewBtn}
        toggle={() => { setViewBtn(!viewBtn) }}
      >
        <DropdownToggle
          caret
          color="lbusers"
          className="btn btn-primary btn-sm"
        >
          Actions &nbsp;{" "}
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu right>
          {type === 'client_list' ?
            <>
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/clients/details`, search: client._id}}> View</DropdownItem>
              <DropdownItem onClick={() => { setEditOpen(true); setSelectedClient(client) }}> Edit</DropdownItem>
              <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedClient(client) }}> Delete</DropdownItem>
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/clients/modules`, search: client._id}}> Modules</DropdownItem>
              </>
            : type === 'client_archieve' ?
            <>
              <DropdownItem onClick={() => { handleRestoreClient(client._id)}}> Restore</DropdownItem>
              <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedClient(client) }}> Delete Permanently</DropdownItem></>
            : type === 'client' ?
            <>
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/clients/details`, search: client._id}}> View</DropdownItem>
              <DropdownItem onClick={() => { setEditOpen(true); setSelectedClient(client) }}> Edit</DropdownItem>
              <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedClient(client) }}> Remove Access</DropdownItem></>
            : type === 'users_list' ? 
            <>
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/users/details`, search: user._id}}> View</DropdownItem>
              <DropdownItem onClick={() => { setEditOpen(true); setSelectedUser(user) }}> Edit</DropdownItem>
              <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedUser(user) }}> Delete</DropdownItem></>
            : <>
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/users/details`, search: user._id}}> View</DropdownItem>
              <DropdownItem onClick={() => { setEditOpen(true); setSelectedUser(user) }}> Edit</DropdownItem>
              <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedUser(user) }}> Remove Access</DropdownItem></>
          }
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  )
}

ButtonDropdownClient.propTypes = {
  state: PropTypes.any,
  handleClick: PropTypes.any
}

export default ButtonDropdownClient