import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import moment from "moment";

const initialState = {
	userData: {
        id: "",
		info_id: "",
		name: "",
		email: "",
		company: "",
        is_2fa_active: false,
		_2fa_frequency: '',
		user_id: '',
		user_name: '',
		user_email: '',
		is_expiration : false,
		payment_overdue : false,
		modules : []
	},
	isProcessingSearch: false,
	onLogout: async ()=> {},
	reloadUserData: async (credentials) => {},
	reloadProcessingSearch: async () => {},
	getProcessingSearch: async () => {},
	loading: false
};

const AuthContext = React.createContext(initialState);

const AuthProvider = ({ children }) => {
	const [userData, setUserData] = useState({
        id: "",
		info_id: "",
		name: "",
		email: "",
		company: "",
        is_2fa_active: false,
		_2fa_frequency: '',
		user_id: '',
		user_name: '',
		user_email: '',
		is_expiration : false,
		payment_overdue : false,
		modules : []
	});
	const [loading, setLoading] = useState(true);
	const [isProcessingSearch, setIsProcessingSearch] = useState(false);

	const onLogout = async () => {
		setUserData(initialState.userData);
		localStorage.clear();
		sessionStorage.clear();
		window.location.href = "/login"
	};

	const reloadUserData = async () => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/customer/token/refresh`, null, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                localStorage.setItem("token", data.token);
                const payload = data.tokenData
                localStorage.setItem("company", payload.company);
                setUserData({
                    id: payload.customer_id,
					info_id: payload.info_id,
                    name: payload.name,
                    email: payload.email,
					company: payload.company,
                    is_2fa_active: payload.is_2fa_active,
					_2fa_frequency: payload._2fa_frequency,
					user_id: payload.user_id,
					user_name: payload.user_name,
					user_email: payload.user_email,
					is_expiration : payload.is_expiration,
					payment_overdue : payload.payment_overdue,
					modules : payload.modules
                });
            }
            else{
                throw new Error(data.title);
            }
		} catch(e) {
            onLogout();
        }

		setLoading(false);
	};

	useEffect(() => {
		const verifyToken = async () => {
			const token = localStorage.getItem("token")
			setLoading(true);
			try {
				const { data } = await axios.post(`${AppConfig.baseUrl}/customer/token`, null, {
					headers: { token: localStorage.getItem("token") }
				});
                if(!data.error){
                    const payload = data.tokenData
                    setUserData({
                        id: payload.customer_id,
						info_id: payload.info_id,
                        name: payload.name,
                        email: payload.email,
						company: payload.company,
                        is_2fa_active: payload.is_2fa_active,
						_2fa_frequency: payload._2fa_frequency,
						user_id: payload.user_id,
						user_name: payload.user_name,
						user_email: payload.user_email,
						is_expiration : payload.is_expiration,
						payment_overdue : payload.payment_overdue,
						modules : payload.modules
                    });
                }
                else{
                    throw new Error(data.title);
                }
			} catch (e) {
                onLogout()
			}
			setLoading(false);
			localStorage.setItem('token', token)
		};

		if (window.location.pathname !== "/login" && window.location.pathname !== '/factorauth') {
			verifyToken();
			getProcessingSearch();
		}
	}, [window.location.pathname]);

	const reloadProcessingSearch = async() => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/search_query/getProcessingSearches`, null, {
                headers: { token: localStorage.getItem("token") }
            });
			if(data.processingCount > 0){
				setIsProcessingSearch(true)
				if(data.searchData){
					toast.success(
					<a style={{color: '#006644'}} href={`/${localStorage.getItem('company')}/${data.searchData.type=='journal_entries'? 'journal' : data.searchData.type}?id=${data.searchData._id}`}>
						Your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type} for {data.searchData.lease_ids.length} leases {data.searchData.type == 'reports' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`} are ready. Please click here to see details.
					</a>);
					if(data.expiredSearch){
						toast.error(<>
							We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
						</>);
					}
				}
				else if(data.expiredSearch){
					toast.error(<>
						We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
					</>);
				}
			}
			else if(data.searchData){
				setIsProcessingSearch(false)
				toast.success(
				<a style={{color: '#006644'}} href={`/${localStorage.getItem('company')}/${data.searchData.type=='journal_entries' ? 'journal' : data.searchData.type}?id=${data.searchData._id}`}>
					Your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type} for {data.searchData.lease_ids.length} leases {data.searchData.type == 'reports' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`} are ready. Please click here to see details.
				</a>);
				if(data.expiredSearch){
					toast.error(<>
						We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
					</>);
				}
			}
			else if(data.expiredSearch){
				setIsProcessingSearch(false)
				toast.error(<>
					We are unable to fetch your {data.searchData.type=="journal_entries" ? 'journal entries': data.searchData.type} for {data.expiredSearch.lease_ids.length} leases {data.searchData.type == 'reports' ? `for date ${moment(data.searchData.date).utc().format('MM/DD/YYYY')}`:`from ${moment(data.searchData.startDate).utc().format('MM/DD/YYYY')} to ${moment(data.searchData.endDate).utc().format('MM/DD/YYYY')}`}.
				</>);
			}
			else{
				setIsProcessingSearch(false)
			}
		} catch (error) {
			console.log('Error getting processing leases: ', error);
		}
	}

	const getProcessingSearch = async() =>{
		const { data } = await axios.post(`${AppConfig.baseUrl}/search_query/getProcessingSearches`, null, {
			headers: { token: localStorage.getItem("token") }
		});
		if(data.processingCount > 0){
			setIsProcessingSearch(true)
		}
		else{
			setIsProcessingSearch(false)
		}
	}

	const value = {
		userData,
		onLogout,
		reloadUserData,
		loading,
		getProcessingSearch,
		reloadProcessingSearch,
		isProcessingSearch,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

AuthProvider.propTypes = {
    children: PropTypes.any,
};

export { AuthProvider, AuthConsumer, AuthContext };
