import React,{useEffect, useState, useContext} from "react"
import {Helmet} from "react-helmet";
import {Container, CardBody, Card, Row, Col, CardTitle, Badge, Spinner, Input,Modal} from "reactstrap"
import {Link} from 'react-router-dom'
import Breadcrumb from "../../components/Common/Breadcrumb";
import toast, { useToaster } from 'react-hot-toast';
import axios from "axios";
import moment from "moment";
import AppConfig from "constants/config";
import { AuthContext } from "context/authContext";
import { capitalize } from "helpers/stringHelper";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const Billing = () => {
    const { userData } = useContext(AuthContext)
    const [billingData, setBillingData] = useState({});
    const [transactionData, setTransactionData] = useState([]);
    const [remainingDays, setRemainingDays] = useState(0);
    const [autoRenewalChecked, setAutoRenewalChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [addPaymentProcessingOpen, setAddPaymentProcessingOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [originalData, setOriginalData] = useState([]);

    const columns = [
        {
            label:'Payment Date',
            name:'transaction_date',
            options:{
                setCellProps: () => ({style: {minWidth: '100px', height: '40px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
                customBodyRender: (value) => {
                    return moment(value).format("MM/DD/YYYY HH:mm")
                }}
        },
        {
            label:'Payment Amount',
            name:'amount',
            options:{
                customBodyRender: (value) => {
                    return `$${value}`
                }
            }
        },
        {
            label:'Payment Method',
            name:'payment_mode',
            options:{
                customBodyRender: (value) => {
                    return value.toUpperCase()
                }
            }
        },
        {
            label:'Last 4 Digits',
            name:'last_4',
        },
        {
            label:'No. of Leases',
            name:'no_of_leases',
        },
        {
            label:'Discount Applied',
            name:'discount',
            options:{
                customBodyRender: (value) => {
                    return value === 'WEBD10' ? '10%' : value === 'FFAD15' ? '15%' : value === 'RCD20' ? '20%' : value === 'FFD30' ? '30%' : '0%' 
                }
            }
        },
        {
            label:'Status',
            name:'status',
            options: {
                customBodyRender: (value) => {
                    return capitalize(value)
                }
            }
        }
    ]

    const addPaymentProcessingForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            admin_email: '',
            admin_name: '',
            other_emails: '',
            phone_number: '',
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                values.id = billingData.customer_id._id;
                const { data } = await axios.post(`${AppConfig.baseUrl}/customer/addPaymentProcessing`, values, {
                    headers: { token: localStorage.getItem("token") }
                });
                if (!data.error) {
                    addPaymentProcessingForm.resetForm();
                    getBillingData()
                    toast.success('Payment process added successfully');
                }
                else {
                    throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setAddPaymentProcessingOpen(false)
            setLoading(false)
        }
    });

    const handleSubmitAddProcessing = () => {
        if(!addPaymentProcessingForm.values.admin_name){
            addPaymentProcessingForm.setFieldError('admin_name','Payment processing admin name is required')
        }
        else if(!addPaymentProcessingForm.values.admin_email){
            addPaymentProcessingForm.setFieldError('processing_email','Payment processing admin email is required')
        }
        else if(addPaymentProcessingForm.values.phone_number && String(addPaymentProcessingForm.values.phone_number).length != 10){
            addPaymentProcessingForm.setFieldError('phone_number','Please enter valid mobile number')
        }else{
            setAddPaymentProcessingOpen(true)
        }
    }

    const getBillingData = async() => {
        setLoading(true)
        try {
            const id = userData.info_id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setBillingData(data.custData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const getTransactionData = async() => {
        setLoadingTransactions(true)
        try {
            const id = userData.id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/transaction/listing?customer_id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setTransactionData(data.transData)
                setOriginalData(data.transData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingTransactions(false)
    }

    useEffect(() => {
        if(userData.info_id){
            getBillingData();
            getTransactionData();
        }
    }, [userData])

    useEffect(() => {
        // if(billingData && billingData.customer_id && billingData.customer_id.start_date && billingData.customer_id.end_date){
        //     const diffDays = moment.utc(billingData.customer_id.end_date).diff(new Date(moment().format('YYYY-MM-DD')), 'days');
        //     setRemainingDays(diffDays > 0 ? diffDays : 0);
        // }
        // if(billingData && billingData.customer_id && billingData.customer_id.auto_renewal){
        //     setAutoRenewalChecked(billingData.customer_id.auto_renewal)
        // }
        // else{
        //     setAutoRenewalChecked(false);
        // }
        addPaymentProcessingForm.setValues({
            admin_name:billingData && billingData.customer_id && billingData.customer_id.payment_processing && billingData.customer_id.payment_processing.admin_name ? billingData.customer_id.payment_processing.admin_name :'',
            admin_email:billingData && billingData.customer_id && billingData.customer_id.payment_processing && billingData.customer_id.payment_processing.admin_email ? billingData.customer_id.payment_processing.admin_email :'',
            phone_number:billingData && billingData.customer_id && billingData.customer_id.payment_processing && billingData.customer_id.payment_processing.phone_number ? billingData.customer_id.payment_processing.phone_number :'',
            other_emails:billingData && billingData.customer_id && billingData.customer_id.payment_processing && billingData.customer_id.payment_processing.other_emails ? billingData.customer_id.payment_processing.other_emails.join() :'',
        })
    }, [billingData])
  
    const handleEditPaymentMethod =  async() => {
        setLoading(true)
        try {
            if(billingData && billingData.customer_id && billingData.customer_id.payment_method_verification_url){
                window.open(billingData.customer_id.payment_method_verification_url, '_blank');
            }else{
                const {data} = await axios.post(`${AppConfig.baseUrl}/stripe/editPaymentMethod`, { id: billingData && billingData.customer_id && billingData.customer_id._id ? billingData.customer_id._id : '' }, {
                    headers: { token: localStorage.getItem("token") }
                });
                if(!data.error){
                    window.location.href = data.session.url
                }
                else{
                    throw new Error(data.title);
                }
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            setLoading(false)
        }
    }

    const handleDeletePaymentMethod =  async() => {
        setLoading(true)
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/stripe/deletePaymentMethod`, { id: billingData && billingData.customer_id && billingData.customer_id._id ? billingData.customer_id._id : '' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                getBillingData()
                toast.success('Payment method deleted successfully.');
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
    
  const handleSearch = (value) => {
    const data = originalData.filter(v => v.transaction_date.match(new RegExp(value,"i")) || v.amount.toString().match(new RegExp(value,"i")) || v.payment_mode.match(new RegExp(value,"i")) || v.status.match(new RegExp(value,"i")))
    setTransactionData(data)
  }

    return(
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Billings</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumb title="Billing" breadcrumbItem="Billing"/>
                    {
                        loading ?
                            <div style={{textAlign: 'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
                        :
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Overview</CardTitle>
                                        <hr />
                                        <div>
                                            <Row>
                                                <Col className="mb-2" md={6}>
                                                    <Row>
                                                        <Col xs={4}> 
                                                            <h6>Start Date:</h6>
                                                        </Col>
                                                        <Col xs={8}> 
                                                            <span style={{color:'black'}}>{billingData && billingData.customer_id && billingData.customer_id.start_date && moment.utc(billingData.customer_id.start_date).format('MM/DD/YYYY')}</span> 
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="mb-2" md={6}>
                                                    <Row>
                                                        <Col xs={4}> 
                                                            <h6>Payment method:</h6>
                                                        </Col>
                                                        <Col xs={8}>
                                                            {
                                                                billingData && billingData.customer_id && billingData.customer_id.payment_method_verification_url ?
                                                                    <>Your payment method needs to be verified, click <a href="/" onClick={handleEditPaymentMethod}>here</a> to verify.</>
                                                                :
                                                                <>
                                                                    {billingData && billingData.customer_id && billingData.customer_id.is_online && billingData.customer_id.card_no ? 'Card ' : billingData && billingData.customer_id && billingData.customer_id.is_online && billingData.customer_id.bank_acc_no ? 'Bank ' :'Other '}
                                                                    {billingData && billingData.customer_id && billingData.customer_id.is_online && billingData.customer_id.card_no ? billingData.customer_id.card_no : billingData && billingData.customer_id && billingData.customer_id.is_online && billingData.customer_id.bank_acc_no ? billingData.customer_id.bank_acc_no :''}
                                                                    {billingData && billingData.customer_id && billingData.customer_id.is_online ? <i className="bx bxs-edit-alt ms-2" style={{fontSize : '20px',color:'#005691', cursor: 'pointer'}} onClick={handleEditPaymentMethod}></i> : ''}
                                                                    {billingData && billingData.customer_id && billingData.customer_id.is_online && (billingData.customer_id.card_no || billingData.customer_id.bank_acc_no) ? <i className="bx bxs-trash text-danger ms-2" style={{fontSize : '20px', cursor: 'pointer'}} onClick={handleDeletePaymentMethod}></i> :''}
                                                                </>
                                                            } 
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col xs={4}> 
                                                            <h6>Payment Type : </h6>
                                                        </Col>
                                                        <Col xs={8}> 
                                                        {billingData && billingData.customer_id && billingData.customer_id.is_online ? 'Auto' : 'Manual'}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {
                                                billingData && billingData.customer_id && billingData.customer_id.is_online && billingData.customer_id.card_no &&
                                                <><hr/>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col xs={4}> 
                                                            <h6>Card Expiry : </h6>
                                                        </Col>
                                                        <Col xs={8}> 
                                                            {billingData && billingData.customer_id && billingData.customer_id.card_expiry_month ? billingData.customer_id.card_expiry_month : ''}/
                                                            {billingData && billingData.customer_id && billingData.customer_id.card_expiry_year ? billingData.customer_id.card_expiry_year : ''}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                </>
                                                }
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="12">
                            <Card className="overflow-hidden">
                                <CardBody>
                                    <CardTitle className="mb-4">Payment Processing</CardTitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Row>
                                                <Col className="mb-2" xs={4}> 
                                                    <h6 className="mt-2"> Admin name:</h6>
                                                </Col>
                                                <Col className="mb-2" xs={8}> 
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="admin_name"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("admin_name")}
                                                        value={addPaymentProcessingForm.values.admin_name}
                                                    />
                                                    {addPaymentProcessingForm.errors.admin_name && (
                                                        <div className="text-danger">{addPaymentProcessingForm.errors.admin_name}</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col xs={4}> 
                                                    <h6 className="mt-2"> Admin email:</h6>
                                                </Col>
                                                <Col xs={8}> 
                                                    <Input
                                                        type="email"
                                                        className="form-control"
                                                        id="admin_email"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("admin_email")}
                                                        value={addPaymentProcessingForm.values.admin_email}
                                                    />
                                                    {addPaymentProcessingForm.errors.admin_email && (
                                                        <div className="text-danger">{addPaymentProcessingForm.errors.admin_email}</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Row>
                                                <Col className="mb-2" xs={4}> 
                                                    <h6 className="mt-2"> Admin number:</h6>
                                                </Col>
                                                <Col className="mb-2" xs={8}> 
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="phone_number"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("phone_number")}
                                                        value={addPaymentProcessingForm.values.phone_number}
                                                    />
                                                    {addPaymentProcessingForm.errors.phone_number && (
                                                        <div className="text-danger">{addPaymentProcessingForm.errors.phone_number}</div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col xs={4}> 
                                                    <h6 className="mt-2"> Others receiving payment,invoicing and account status emails:</h6>
                                                </Col>
                                                <Col xs={8}> 
                                                    <Input
                                                        type="textarea"
                                                        rows={2}
                                                        className="form-control"
                                                        id="other_emails"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("other_emails")}
                                                        value={addPaymentProcessingForm.values.other_emails}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <button type="button" onClick={handleSubmitAddProcessing} className="btn bg-theme-color w-md" disabled={billingData && billingData.customer_id && billingData.customer_id.is_deleted === true}>
                                        SUBMIT
                                    </button>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                        MUIDataTableToolbar:{
                                            styleOverrides:{
                                            actions: {
                                                display: 'contents'
                                            }
                                            }
                                        }
                                    }})}>
                                        <MUIDataTable
                                            title={
                                                <Row className="row">
                                                    <Col></Col>
                                                    <Col md="4" className="search-p">
                                                    <div className="me-2 mb-3">
                                                        <div className="position-relative sw">
                                                        <Input
                                                            placeholder="Search"
                                                            type="text"
                                                            value={search}
                                                            onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                                                        />
                                                        </div>
                                                    </div>
                                                    </Col>
                                                </Row>
                                                }
                                            data={transactionData}
                                            columns={columns}
                                            options={{ 
                                            rowsPerPage:20, 
                                            print:false, 
                                            download:false, 
                                            filter:false, 
                                            selectableRows:"none", 
                                            search:false, 
                                            rowsPerPageOptions:[], 
                                            responsive:'scroll',
                                            textLabels: {
                                                body: {
                                                  noMatch: loadingTransactions ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                },
                                                viewColumns: {
                                                title: "",
                                                },
                                            } 
                                            }}
                                        />
                                    </ThemeProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={addPaymentProcessingOpen}
                toggle={() => {
                    setAddPaymentProcessingOpen(!addPaymentProcessingOpen)
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Update Payment Processing Details</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setAddPaymentProcessingOpen(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={addPaymentProcessingForm.handleSubmit}>
                        <div className="mb-3"> 
                            Are you sure you want to update payment processing details for this account ?
                        </div>
                        <div className="modal-footer mt-0">
                            <button
                                type="button"
                                className="btn bg-theme-color"
                                onClick={() =>
                                    setAddPaymentProcessingOpen(false)
                                }
                                disabled={loading}
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="submit" className="btn bg-theme-color" disabled={loading}>
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Layout>
    )
}
    
export default Billing;
