import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Modal, Label, Input } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import avatar from "../../assets/images/users/avatar-1.jpg";
import axios from "axios";
import AppConfig from "../../constants/config";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext";
import CropImage from "components/Common/CropImage";

const AddUser = (prop) => {
	const { userData } = useContext(AuthContext);
    const { addOpen, toggleAdd, setOpenPopup, getUserData } = prop;
    const [clients, setClients] = useState([]);
    const [image, setImage] = useState(avatar);
    const [cropOpen, setCropOpen] = useState(false);
    const [clientName, setClientName] = useState("");
    const [loading, setLoading] = useState(false);
    const [custData, setCustData] = useState({})
    
    const handleSubmit = async(values) => {
        setLoading(true)
        try {
            values.profile_picture = image !== avatar ? image : '';
            const {data} = await axios.post(`${AppConfig.baseUrl}/users/register`, values,{
                headers:{token: localStorage.getItem('token')}
            });
            if(!data.error){
                toast.success('User added successfully');
                getUserData();
                handleClosePopup()
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            handleClosePopup()
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
    const getCustomerData = async() => {
        setLoading(true)
        try {
            const id = userData.info_id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setCustData(data.custData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
    useEffect(() => {
    if(userData.info_id){
        getCustomerData()
    }
    },[userData])
    const handleClosePopup = () => {
        formik.resetForm();
        setOpenPopup(false);
    }
  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            name: '',
            type: 'user',
            access_type: 'full_access',
            client_id: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Please Enter Valid Email").required("Please Enter Your Email"),
            name: Yup.string().required("Please Enter Your Name"),
            client_id: Yup.string().when("type", {is: 'third_party', then: (schema) =>  schema.required("Please select client name"), otherwise: (schema) =>  schema})
        }),
        onSubmit: handleSubmit
    });

    const handleChange = async(e, key) => {
        formik.setFieldValue(key, e.target.value)
    }

    useEffect(() => {
        const getAllClients = async() =>{
            try {
                let type = ''
                if(!custData || !custData.customer_id || !custData.customer_id.modules || !custData.customer_id.modules.includes('lessor_gasb')){
                    type = "lessee"
                }
                const {data} = await axios.get(`${AppConfig.baseUrl}/client/list_client?type=${type}`,{
                    headers:{token: localStorage.getItem('token')}
                });
                if(!data.error){
                    setClients(data.clientData)
                }
                else{
                    throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
        }

        getAllClients()
    }, [custData])

    const onSelectFile = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setCropOpen(true);
        };
        reader.readAsDataURL(files[0]);
    };

    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                toggleAdd();
            }}
            size="md"
            centered
        >
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Add User</h5>
                <button
                    type="button"
                    onClick={handleClosePopup}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={6} md={6} xl={6} xxl={6}>
                            <div className="image-area mt-3 mb-3">
                                <label style={{width: '100px', height:'100px', cursor:'pointer'}}>
                                    <input type="file" multiple={false} onChange={onSelectFile} style={{display:'none'}} />
                                        <img src={image} />
                                </label>
                                {
                                    image !== avatar && 
                                    <i className='bx bx-x remove-image' style={{display: 'inline-block'}} onClick={() => setImage(avatar)}></i>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3">
                                <label className="col-form-label">Type:</label>
                                <select
                                    className="form-control arrows" 
                                    id="clientDataList" 
                                    type='select' 
                                    name="type"
                                    onChange={(e) => {
                                        handleChange(e, "type")
                                    }}
                                    onBlur={() => formik.setFieldTouched("type")}
                                >
                                    <option name="user" value="user" defaultChecked>{userData.company} User</option>
                                    <option name="third_party" value="third_party">{clientName && formik.values.type === 'third_party' ? clientName + " user": "Third party user"}</option>
                                    <option name="admin" value="admin">Admin</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-2">
                                <Label for="name" className="col-form-label">
                                    Name:
                                </Label>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name || ""}
                                />
                                {formik.touched.name && formik.errors.name &&
                                    <div className="text-danger">{formik.errors.name}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3">
                                <Label for="email" className="col-form-label">
                                    Email:
                                </Label>
                                <Input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email || ""}
                                />
                                {formik.touched.email && formik.errors.email &&
                                    <div className="text-danger">{formik.errors.email}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3 mb-3">
                                <label className="col-form-label">Client</label>
                                <select 
                                    className="form-control"
                                    name="client_id"
                                    onChange={(e) => {
                                        const client = clients.filter(v => v._id === e.target.value);
                                        setClientName(client[0].client)
                                        formik.handleChange(e)
                                    }}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Please select client</option>
                                    {
                                        clients.map(val => {
                                            return (<option key={val._id} value={val._id}>{val.client}</option>)
                                        })
                                    }
                                </select>
                                {formik.touched.client_id && formik.errors.client_id &&
                                    <div className="text-danger">{formik.errors.client_id}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            <CropImage cropOpen={cropOpen} setCropOpen={setCropOpen} image={image} setImage={setImage} />
        </React.Fragment>
        </Modal>
    )
}

export default AddUser