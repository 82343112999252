import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Modal, Input, Spinner, Table } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { AuthContext } from "context/authContext";

const AddUserToClient = (prop) => {
    const { userData } = useContext(AuthContext)
    const { addOpen, toggleAdd, setOpenPopup, client, getClientDetails } = prop
    const [users, setUsers] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [filterUsers, setFilterUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [type, setType] = useState('')

    useEffect(() => {
        const getAvailableUsers = async() => {
            setLoading(true)
            try {
                const {data} = await axios.get(`${AppConfig.baseUrl}/client/available_users?client_id=${client._id}&customer_id=${userData.id}`,{
                  headers: { token: localStorage.getItem("token") }
                });
                if(!data.error){
                    setUsers(data.availableUsers)
                    setFilterUsers(data.availableUsers)
                }
                else{
                  throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong')
            }
            setLoading(false)
        }
        if(client && client._id && userData && userData.id){
            getAvailableUsers();
        }
    },[client])
    
    const checkAll = (type) => {
        const data = users.filter(v => type == "delete" ? v.deletable : v.is_processed)
        return users.length > 0 && data.length == users.length
    }
    
    const onSubmit = async () => {
        setIsSubmitting(true)
        try {
            let usersData = users.filter(v => v.is_processed)
            let values = {client_id : client._id, users : usersData }
            const { data } = await axios.post( `${AppConfig.baseUrl}/user_client/add_users_client`, values, {
                headers: { token: localStorage.getItem("token")},
            });
            if (!data.error) {
                toast.success("Users added to client successfully");
                getClientDetails(client._id)
            } else {
                throw new Error(data.title)
            }
            setOpenPopup(false);
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setIsSubmitting(false)
    }

    const handleUsers = (value) =>{
        if(value){
            const data = [...filterUsers].filter(v => {
                if(type){
                    return v.name.match(new RegExp(value,"i")) && v.type == type
                }else{
                    return v.name.match(new RegExp(value,"i"))
                }
            })
            setUsers(data)
        }else if(type){
            const data = [...filterUsers].filter(v =>v.type == type)
            setUsers(data)
        }else{
            setUsers(filterUsers)
        }
    }

    const handleType = (value) =>{
        if(value){
            const data = [...filterUsers].filter(v =>{
                if(search){
                    return v.name.match(new RegExp(search,"i")) && v.type == value
                }else{
                    return v.type == value
                }
            })
            setUsers(data)
        }else if(search){
            const data = [...filterUsers].filter(v =>v.name.match(new RegExp(search,"i")))
            setUsers(data)
        }else{
            setUsers(filterUsers)
        }
    }

    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                toggleAdd();
            }}
            size="lg"
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">{`Add users for ${client.client}`}</h5>
                <button
                    type="button"
                    onClick={() => {
                        setOpenPopup(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {
                    loading ?
                    <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                    :   
                    <div className="modal-body">
                        <Row>
                            <Col md="4">
                                <Input
                                placeholder="Search"
                                    className="mb-2"
                                    name="search"
                                    type="text"
                                    onChange={(e) => {setSearch(e.target.value); handleUsers(e.target.value)}}
                                    value={search}
                                />
                            </Col>
                            <Col md="4">
                                <select
                                    className="form-control arrows" 
                                    type='select' 
                                    name="type"
                                    value={type}
                                    onChange={(e) =>{setType(e.target.value);handleType(e.target.value)}}
                                >
                                    <option name="" value="" defaultChecked>Select type</option>
                                    <option name="user" value="user" defaultChecked>{userData.company.toUpperCase()} USER</option>
                                    <option name="third_party" value="third_party">THIRD PARTY USER</option>
                                    <option name="admin" value="admin">ADMIN</option>
                                </select>
                            </Col>
                        </Row>
                        <div className="table-responsive">
                            <Table className="table mb-0 text-center customTable">
                                <thead className="table-light tableHead">
                                    <tr style={{paddingRight:'15px'}}>
                                        <th>
                                            <div className="mt-4">
                                                <Input
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    type="checkbox"
                                                    checked={checkAll()}
                                                    onClick={(e) => {
                                                        if (e.target.checked) {
                                                            const newArr = users.map(v => {
                                                                v.is_processed = true
                                                                return v;
                                                            })
                                                            setUsers(newArr)
                                                        } else {
                                                            const newArr = users.map(v => {
                                                                v.is_processed = false
                                                                return v;
                                                            })
                                                            setUsers(newArr)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th>User</th>
                                        <th>Type</th>
                                        <th>Access Type</th>
                                        <th>Lease Review</th>
                                    </tr>
                                </thead>
                                <tbody className="tableBody">
                                {   users && users.length < 1 ?
                                <>
                                    <tr><td colSpan={5}>No data found</td></tr>
                                </>
                                :
                                    (users && users.length > 0) && users.map((data, i) => {
                                        return <tr key={data.type + i} style={{ textDecoration: data.deletable ? 'line-through' : 'none', textDecorationColor: data.deletable ? 'red' : '' }} >
                                            <td className="text-center">
                                                <div className="mt-2">
                                                    <Input
                                                        className="form-check-input"
                                                        name="includePayment"
                                                        id={i}
                                                        type="checkbox"
                                                        disabled={data.deletable}
                                                        checked={data.is_processed}
                                                        onClick={() => {
                                                            let newArr = [...users];
                                                            newArr[i].is_processed = newArr[i].is_processed ? false : true
                                                            setUsers(newArr)
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td><div className="mt-2">{data.name ? data.name : ''}</div></td>
                                            <td><div className="mt-2">{data.type && data.type == 'user' ? userData.company.toUpperCase() + ' UESR' : data.type ? data.type.toUpperCase() : ''}</div></td>
                                            <td><div className="mt-2">
                                                <select
                                                    className="form-control arrows" 
                                                    type='select' 
                                                    id={i}
                                                    name="access_type"
                                                    onChange={(e) => {
                                                        let newArr = [...users];
                                                        newArr[i].access_type = e.target.value
                                                        setUsers(newArr)
                                                    }}
                                                >
                                                    <option name="full_acess" value="full_access">Full Access</option>
                                                    <option name="read_only" value="read_only">Read Only</option>
                                                </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex mb-0 justify-content-center">
                                                    <div className="form-check form-switch form-switch-md mt-2 ms-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input switch-icon-color"
                                                            id="can_review"
                                                            name="can_review"
                                                            checked = {data.can_review || false}
                                                            onChange={(e) => {
                                                                let newArr = [...users];
                                                                newArr[i].can_review = true
                                                                setUsers(newArr)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })                                    
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }
                <div className="modal-footer mt-3">
                    <button type="button" className="btn btn-lbusers" disabled={isSubmitting} onClick={onSubmit}>
                        {
                            isSubmitting && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        SUBMIT
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default AddUserToClient