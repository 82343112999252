import React from "react";
import Layout from "components/VerticalLayout";

class Success extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: ''
        };
    }

    componentDidMount(){
        const search = window.location.search.replace('?', '');
        this.setState({type: search})
    }

    render() {
        return (
            <Layout>
                
                <div className="main">
                    <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
                        <div className="background-image-wraper" style={{backgroundImage: "url(background.jpg)",opacity:0.4}}></div>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12">
                                    <div className="hero-content-left text-black">
                                        <br/><br/><br/><br/><br/>
                                        <h2 className="text-black">Thank You!</h2>
                                        <br/>
                                        <p className="lead">
                                            We have recorded your request for updating payment method.
                                            <br/>
                                            {
                                            this.state.type === 'payment_method' ?
                                                <>
                                                    To validate your ACH account, 
                                                    Stripe will make a micro-deposit of $0.01 to your account in 1-2 business days. 
                                                    Please find the validation code in your bank account statement under this transaction that looks like - SMXXXX. 
                                                    <br/>
                                                    Please use this code to validate your ACH account by following the steps mentioned in an email you will get from us.
                                                </>
                                            :
                                                <>
                                                    You will receive a email from us as soon as your payment method is updated. 
                                                </>
                                            }
                                        </p>
                                        <br/>
                                        <p className="lead">
                                            Thank you!
                                            <br/>
                                            LeaseJava Team
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                      </div>
                    </section>
                </div>
               
            </Layout>
        );
    }
}

export default Success;
