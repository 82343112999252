import React, {useEffect, useContext, useState} from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import VerticalLayout from "./components/VerticalLayout/"
import "./assets/scss/theme.scss"
import { useIdleTimer } from "react-idle-timer"
import moment from "moment"
import { AuthContext } from 'context/authContext'
import Dashboard from "pages/Dashboard"
import Logout from "pages/Authentication/Logout"
import Login from "pages/Authentication/Login"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"
import FactorAuth from "pages/Authentication/FactorAuth"
import SetPassword from "pages/Authentication/setPassword"
import TermsAndConditions from "pages/Authentication/TermsAndConditions"
import Users from "pages/Users/users"
import UserDetails from "pages/Users/userDetails"
import Clients from "pages/Myclients/clients"
import ClientDetails from "pages/Myclients/clientdetails"
import Modules from "pages/Myclients/modules"
import ActivityLog from "pages/Myclients/activityLog"
import Billing from "pages/Billings/billing"
import Success from "pages/Payments/Success"
import Failure from "pages/Payments/Failure"
import Reports from "pages/Reports"
import UserProfile from "pages/Authentication/user-profile"
import Settings from "pages/Authentication/Settings"
import ResetPassword from "pages/Authentication/resetPassword"

const App = () => {
  const {getLastActiveTime} = useIdleTimer()
  const [interval1, setInt1] = useState('')
  const { isProcessingSearch, reloadProcessingSearch } = useContext(AuthContext);

  useEffect(() => {
    const lastActive = localStorage.getItem('last_active')
    if(moment().diff(moment(lastActive), 'minutes') > 30){
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login"
    }
    setInterval(() => {
      localStorage.setItem("last_active", getLastActiveTime())
    }, 10000)
  },[])

  useEffect(() => {
    if(isProcessingSearch){
      let int = setInterval(() => {
        reloadProcessingSearch()
      }, 3000)
      setInt1(int)
    }
    else{
      clearInterval(interval1)
    }
  }, [isProcessingSearch])

  function getLayout() {
    let layoutCls = VerticalLayout
    return layoutCls
  }
  
  const Layout = getLayout()

  return (
    <React.Fragment>
      <Routes>
        {/* Dashboard */}
        <Route path = "/:company/dashboard" element = { <Dashboard/> }/>

        {/* Users */}
        <Route path = "/:company/users" element = {!localStorage.getItem("token") ? <Navigate to="/login"/> : <Users/>}/>
        <Route path = "/:company/users/details" element = {!localStorage.getItem("token") ? <Navigate to="/login"/> : <UserDetails/>}/>

        {/* Clients */}
        <Route path = "/:company/clients" element = {!localStorage.getItem("token") ? <Navigate to="/login"/> : <Clients/>}/>
        <Route path = "/:company/clients/details" element = {!localStorage.getItem("token") ? <Navigate to="/login"/> : <ClientDetails/>}/>
        <Route path = "/:company/clients/modules" element = {!localStorage.getItem("token") ? <Navigate to="/login"/> : <Modules/>}/>
        <Route path = "/:company/clients/activitylog/:id" element = {!localStorage.getItem("token") ? <Navigate to="/login"/> : <ActivityLog/>}/>

        {/* Billing */}
        <Route path = "/:company/billing" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Billing/>}/>

        {/* Payment */}
        <Route path = "/:company/success" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Success/> }/>
        <Route path = "/:company/failed" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Failure/> }/>

        {/* Report */}
        <Route path = "/:company/reports" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Reports/>}/>

        {/* User Profile */}
        <Route path= "/:company/profile" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <UserProfile/> }/>

        {/* settings */}
        <Route path= "/:company/settings" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Settings/> }/>

        {/* publicRoutes */}
        <Route path = "/logout" element = { <Logout/> }/>
        <Route path = "/login" element = { <Login/> }/>
        <Route path = "/forgot-password" element = { <ForgetPwd/> }/>
        <Route path = "/register" element = { <Register/> }/>
        <Route path = "/factorauth" element = { <FactorAuth/> }/>
        <Route path = "/set_password" element = { <SetPassword/> }/>
        <Route path = "/terms_and_conditions/:type" element = { <TermsAndConditions/> }/>
        <Route path = "/reset_password" element={ <ResetPassword/> } />

        <Route path = "/" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Navigate to={`/${localStorage.getItem('company')}/dashboard`} /> }/>
        <Route path = "/:any" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> :  <Navigate to={`/${localStorage.getItem('company')}/dashboard`} /> }/>
        {/* <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch> */}
      </Routes>
    </React.Fragment>
  )
}

export default App
