import React, { useState , useEffect} from "react";
import { InputGroup, Modal } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

const DateModal = (prop) => {
    const {datePopupOpen, setDatePopupOpen, type, selectedLeases, jeType, handleChangeJeType , dateType, handleChangeType} = prop;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [weekType, setWeekType] = useState(1);
    const [dayType, setDayType] = useState(0);
    const [monthType, setMonthType] = useState(0);
    const [yearDate, setYearDate] = useState('12/31');

    const handleChangeDateType = (e) => {
        handleChangeJeType(e);
        setStartDate('');
        setEndDate('');
    }
    
   

    return (
        <Modal
            isOpen={datePopupOpen}
            toggle={() => {
                setDatePopupOpen(!datePopupOpen);
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Select Dates</h5>
                <button
                    type="button"
                    onClick={() => setDatePopupOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={(e) => {
                    e.preventDefault()
                    setDatePopupOpen(false);
                    prop.handleClick(selectedLeases, startDate, endDate, jeType, dateType, weekType, dayType, monthType, yearDate)
                }}>
                    {
                        type === 'journal_entries' &&
                        <>
                        <div className="form-check mb-3 me-3" style={{display:'inline-block'}}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="je_type"
                            id="monthly"
                            value="monthly"
                            checked={jeType === 'monthly'}
                            onChange={handleChangeDateType}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="monthly"
                        >
                            Monthly 
                        </label>
                        </div>
                        <div className="form-check" style={{display:'inline-block'}}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="je_type"
                            id="daily"
                            value="daily"
                            checked={jeType === 'daily'}
                            onChange={handleChangeDateType}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="daily"
                        >
                            Daily 
                        </label>
                        </div>
                        </>
                    }
                    {
                        type === 'reports' &&
                            <InputGroup className="mb-2">
                                <Flatpickr
                                    onClose={([date]) => {
                                        if(date){
                                            setStartDate(moment(date).format('MM/DD/YYYY'));
                                        }
                                    }}
                                    value={startDate}
                                    className="form-control d-block bg-white"
                                    placeholder="Select date"
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y",
                                        allowInput: true,
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                        }
                        { jeType === 'monthly' && type === 'journal_entries' &&
                        <>
                            <InputGroup className="mb-3">
                                <Flatpickr
                                    onClose={([date]) => {
                                        if(date){
                                            setStartDate(date);
                                            setEndDate('')
                                        }
                                        else{
                                            setStartDate('');
                                            setEndDate('')
                                        }
                                    }}
                                    value={startDate ? moment(startDate).format('MMMM YYYY') : ''}
                                    className="form-control d-block bg-white"
                                    placeholder="Start month & year"
                                    options={{
                                        plugins: [
                                            new monthSelectPlugin({
                                                shorthand: true,
                                                dateFormat: "F Y",
                                                altFormat: "F Y", 
                                            })
                                        ]
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                            <InputGroup>
                                <Flatpickr
                                    onClose={([date]) => {
                                        if(date){
                                            setEndDate(moment(date).endOf('M').format('MM/DD/YYYY'));
                                        }
                                        else{
                                            setEndDate('');
                                        }
                                    }}
                                    value={endDate ? moment(endDate).format('MMMM YYYY') : ''}
                                    className="form-control d-block bg-white"
                                    placeholder="End month & year"
                                    options={{
                                        plugins: [
                                            new monthSelectPlugin({
                                                shorthand: true,
                                                dateFormat: "F Y",
                                                altFormat: "F Y", 
                                            })
                                        ],
                                        disable:[(date) => moment(date).isBefore(moment(startDate))]
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                        </>
                        }
                        { ((type === 'journal_entries' && jeType !== 'monthly') || type === 'disclosures') &&
                        <>
                            <InputGroup className="mb-3">
                                <Flatpickr
                                    onClose={([date]) => {
                                        if(date){
                                            setStartDate(moment(date).format('MM/DD/YYYY'));
                                            setEndDate(moment(date).endOf('month').format('MM/DD/YYYY'))
                                        }
                                        else{
                                            setStartDate('');
                                            setEndDate('')
                                        }
                                    }}
                                    value={startDate}
                                    className="form-control d-block bg-white"
                                    placeholder="Start date"
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y",
                                        allowInput: true,
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                            <InputGroup>
                                <Flatpickr
                                    onClose={([date]) => {
                                        if(date){
                                            setEndDate(moment(date).format('MM/DD/YYYY'));
                                        }
                                        else{
                                            setEndDate('');
                                        }
                                    }}
                                    value={endDate}
                                    className="form-control d-block bg-white"
                                    placeholder="End date"
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y",
                                        allowInput: true,
                                        disable:[(date) => {
                                            if(type === 'journal_entries'){
                                                return moment(date).isBefore(moment(startDate))
                                            }else{
                                                return moment(date).isBefore(moment(startDate))
                                            }
                                        } ]
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                        </>
                    }
                    {
                        (type === "reports" || type === "disclosures") && 
                        <div className="mt-3">
                            <div>
                                <select className="form-control arrows" 
                                placeholder="Select year end date type"
                                onChange={(e)=>{handleChangeType(e)}}
                                value={dateType} style={{width:"466px", display: "inline-block"}}>
                                    <option value="exactDate">Year end - exact date</option>
                                    <option value="description">Year end - date from description (beta)</option>
                                </select>
                                </div>
                                {
                                dateType === "exactDate" ?
                                <InputGroup className="mt-3">
                                    <Flatpickr
                                        onClose={([date]) => {
                                            if(date){
                                                setYearDate(moment(date).format('MM/DD'));                                          
                                            }
                                            else{
                                                setYearDate("")
                                            }
                                        }}
                                        value={yearDate}
                                        className="form-control d-block bg-white"
                                        placeholder="Select year end date"
                                        options={{
                                            altInput: true,
                                            altFormat: "m/d",
                                            dateFormat: "m/d",
                                            allowInput: true,
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                            disabled
                                        >
                                            <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </InputGroup>
                                :
                                <div className="mt-3">
                                    <select className="form-control arrows" 
                                        onChange={(e)=>{setWeekType(e.target.value)}}
                                        value={weekType} style={{width:"90px", display: "inline-block"}}>
                                            <option value={1}>First</option>
                                            <option value={2}>Second</option>
                                            <option value={3}>Third</option>
                                            <option value="last">Last</option>
                                    </select>

                                    <select className="form-control arrows ms-1" 
                                        onChange={(e)=>{setDayType(e.target.value)}}
                                        value={dayType} style={{width:"90px", display: "inline-block"}}>
                                            <option value={0}>Sunday</option>
                                            <option value={1}>Monday</option>
                                            <option value={2}>Tuesday</option>
                                            <option value={3}>Wednesday</option>
                                            <option value={4}>Thursday</option>
                                            <option value={5}>Friday</option>
                                            <option value={6}>Saturday</option>
                                    </select>
                                    <div style={{display:"inline-block"}} className="me-2 ms-2">Of</div>
                                    <select className="form-control arrows" 
                                        onChange={(e)=>{setMonthType(e.target.value)}}
                                        value={monthType} style={{width:"90px", display: "inline-block"}}>
                                            <option value={0}>January</option>
                                            <option value={1}>February</option>
                                            <option value={2}>March</option>
                                            <option value={3}>April</option>
                                            <option value={4}>May</option>
                                            <option value={5}>June</option>
                                            <option value={6}>July</option>
                                            <option value={7}>August</option>
                                            <option value={8}>September</option>
                                            <option value={9}>October</option>
                                            <option value={10}>November</option>
                                            <option value={11}>December</option>
                                    </select>
                                    </div>
                              }
                        </div>

                    }
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={type === 'reports' ? (!startDate || (dateType === 'exactDate' && !yearDate)) : type === 'disclosures' ? (!startDate || !endDate || (dateType === 'exactDate' && !yearDate)) : !startDate || !endDate }>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default DateModal