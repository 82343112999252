import React from "react"
import { createRoot } from "react-dom/client";
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Toaster } from 'react-hot-toast';
import { MainProvider } from "context/mainProvider";

const root = createRoot(document.getElementById("root"));
root.render(
  <MainProvider>
    <BrowserRouter>
      <Toaster position="top-right" reverseOrder={false}/>
        <App />
    </BrowserRouter>
  </MainProvider>
)

// ReactDOM.render(app, document.getElementById("root"))
