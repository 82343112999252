import React, { useState , useEffect, useContext} from "react";
import PropTypes from 'prop-types'
import {
    Col,
    Input,
    Row,
    Card,
    CardBody,
    Button, Spinner,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import MultiSelect from "components/Common/MultiSelect";
import AppConfig from "constants/config";
import moment from "moment";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import DateModal from './datePopup'
import toast from 'react-hot-toast';
import axios from "axios";
import { Alert } from 'reactstrap';
import { AuthContext } from "context/authContext";
import BrowserSettingPopup from "./browserSettingPopup";
import {Buffer} from 'buffer';

const Filter = (prop) => {
    const { lesseeClient, lessorClient, loading:loadingClients, type, jeType, setJEType, handleChangeJeType, dateType, setDateType, handleChangeType , getSearchResult, mode, setMode} = prop;
    const {isProcessingSearch} = useContext(AuthContext);
    const { userData,reloadUserData } = useContext(AuthContext);
    const [options, setOptions] = useState([]);
    const [selectedClients,setSelectedClients] = useState(null)
    const [leaseData, setLeaseData] = useState([]);
    const [selectedLeases,setSelectedLeases] = useState([])
    const [leaseName, setLeaseName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [classification, setClassification] = useState("");
    const [loading, setLoading] = useState(false);
    const [datePopupOpen, setDatePopupOpen] = useState(false);
    const [diffCurrency, setDiffCurrency] = useState(false);
    const [recentSearches, setResentSearches] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [currency, setCurrency] = useState('');
    const [fasbSelected, setFasbSelected] = useState(false);
    const [gasbSelected, setGasbSelected] = useState(false);
    const [ifrsSelected, setIfrsSelected] = useState(false);
    const [browserSetting, setBrowserSetting] = useState(false);
    const [custData, setCustData] = useState({})

    const getCustomerData = async() => {
        setLoading(true)
        try {
            const id = userData.info_id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setCustData(data.custData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
    useEffect(() => {
    if(userData.info_id){
        getCustomerData()
    }
    },[userData])

    useEffect(() => {
        if(mode == 'lessor'){
            const opt = lessorClient.map(client => {
                return {label: client.client, value: client._id}
            });
            setOptions(opt);
        }else{
            const opt = lesseeClient.map(client => {
                return {label: client.client, value: client._id}
            });
            setOptions(opt);
        }
    }, [lessorClient,lesseeClient,mode])

    useEffect(() => {
        setSelectedClients([])
        setLeaseData([])
    },[mode])

    useEffect(() => {
        getRecentSerches()
    },[mode])

    useEffect(()=>{
        let currData = []
        if(leaseData && leaseData.length > 0 && currency == ''){
            for(const i of leaseData){
                const data = currData.filter( c => c._id == i.currency._id)
                if(data.length<1){
                    currData.push(i.currency)
                }
            } 
        }else if(currency != ''){
            currData=currencyData
        }
        setCurrencyData(currData)
    },[leaseData])

    const getLeasesWithDiffCurrency = async() =>{
        const leasesWithDiffCurrency = await selectedLeases.filter(v => {
            if(v.currency && selectedLeases[0].currency && v.currency.code !== selectedLeases[0].currency.code){
                return v
            }
            else if(!v.currency && selectedLeases[0].currency && selectedLeases[0].currency.code !== 'USD'){
                return v
            }
            else if(v.currency && !selectedLeases[0].currency && v.currency.code !== 'USD'){
                return v
            }
            else{
                return
            }
        })
        if(leasesWithDiffCurrency && leasesWithDiffCurrency[0]){
            setDiffCurrency(true);
        }else{
            setDiffCurrency(false);
        }
    }

    useEffect(() => {
        if(selectedLeases && selectedLeases.length > 0 && type !== 'journal_entries'){
            getLeasesWithDiffCurrency()
        }else{
            setDiffCurrency(false);
        }
    },[selectedLeases])

    useEffect(() => {
        getRecentSerches()
    },[isProcessingSearch])

    const getLeaseData = async() => {
        setLeaseData([])
        setLoading(true);
        setFasbSelected(false);
        setGasbSelected(false);
        setIfrsSelected(false);
        setSelectedLeases([])
        try {
            const clientIds = selectedClients ? selectedClients.map(client => client.value) : [];
            const bodyData = {
                clients: clientIds,
                leaseName,
                city,
                state,
                classification,
                currency,
                mode: mode ? mode : 'lessee'
            }
            let {data} = await axios.post(`${AppConfig.baseUrl}/lease/get_filter_leases`, {...bodyData}, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(data.error){
                throw new Error(data.title)
            }
            else{
                setLeaseData(data.leaseData || [])
            }
        } catch(err){
            console.log('error in listing lease ->',err)
        }   
        setLoading(false);
    }

    const getRecentSerches = async() => {
        setLoading(true);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/search_query/recent?type=${type}&mode=${mode ? mode : 'lessee'}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setResentSearches(data.recentSearches || []);
            }
            else{
                setResentSearches([])
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
    
    const handleChange = (selected) =>{
        setSelectedLeases([])
        setLeaseData([])
        setSelectedClients(selected)
    }

    const checkArr = (data) => {
        setFasbSelected(false)
        setGasbSelected(false)
        setIfrsSelected(false)
        let newArr = [...selectedLeases];
        let index = newArr.findIndex((e) => e == data);
        if(index > -1){
            newArr.splice(index, 1);
            setSelectedLeases(newArr);
        } else {
            newArr.push(data);
            setSelectedLeases(newArr);
        }
    }

    const showCheck = (data) => {
        let a = selectedLeases.filter((e) => e == data);
        return a.length > 0 ? true : false
    }

    const checkWithStandard = (e, standard) => {
        if(e.target.checked === true) {
            if(standard == 'FASB ASC 842'){
                setFasbSelected(true)
                setGasbSelected(false)
                setIfrsSelected(false)
            }
            else if(standard == 'GASB 87'){
                setGasbSelected(true)
                setFasbSelected(false)
                setIfrsSelected(false)
            }else{
                setGasbSelected(false)
                setFasbSelected(false)
                setIfrsSelected(true)
            }
            const filteredLeases = leaseData.filter(v => v.reporting_standard == standard)
            setSelectedLeases(filteredLeases) 
        }
        else {
            setFasbSelected(false);
            setGasbSelected(false);
            setIfrsSelected(false)
            setSelectedLeases([])
        }
    }

    const createDownloadLink = (data,name) =>{
        const buffer = Buffer.from(data)
        const url = window.URL.createObjectURL(new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click(e => {
            e.preventDefault()
        })
    }

    const downloadTemplate = async(check) => {
        setLoading(true);
        try {
            let ids = []
            for(const lease of selectedLeases){
                ids.push(lease._id)
            }
            const body = {
                lease_ids: ids,
                checked_browser_setting : check,
                mode: mode ? mode : 'lessee'
            }
            let {data:value} = await axios.post(`${AppConfig.baseUrl}/report/exportLeaseCSV`,body, {
                headers: { token: localStorage.getItem("token") },
            })
            if (!value.error) {
                createDownloadLink(value.payments,'lease_payments.xlsx') 
                setTimeout(()=>{
                    createDownloadLink(value.details,'lease_details.xlsx')
                },1000)
                reloadUserData()
                toast.success('CSV downloaded successfully');
            }
            else {
                throw new Error(value.title);
            }
        } catch (error) {
            console.log(error)
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const toggleBrowserSetting = () => { 
        setBrowserSetting(!browserSetting)  
    }

    const handleChangeMode= (e) => {
        setMode(e.target.value);
    }
    return (
        <React.Fragment>
            <Card className="overflow-hidden">
                <CardBody>
                    <Row>
                        {custData && custData.customer_id && custData.customer_id.modules && custData.customer_id.modules.includes('lessor_gasb') &&
                            <Col md = '4'>
                                <select
                                    value={mode}
                                    onChange={handleChangeMode}
                                    id="formrow-InputState"
                                    className="form-control form-control-md arrows"
                                >
                                    <option name="lessee" value="lessee">Lessee Mode</option>
                                    <option name="lessor" value="lessor">Lessor Mode (experiment)</option>
                                </select>
                            </Col>
                        }
                        <Col md='4'>
                            <MultiSelect options={options} handleChange={handleChange} selectedOptions={selectedClients} loading={loadingClients} />
                        </Col>
                        <Col md='4'>
                            <button className="btn btn-lbusers" onClick={getLeaseData} disabled={loading} >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Get Leases
                            </button>
                        </Col>
                    </Row>

                    <Card className="mt-4 mb-0 card-bg">
                        <CardBody>
                            <Row>
                                <Col md='12'>
                                    <Row>
                                        <Col lg='2' md="6" className="mt-1">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Type lease name..."
                                                onChange={(e) => setLeaseName(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg='2' md="6" className="mt-1">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="formrow-firstname-Input"
                                                placeholder="Type city"
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg='2' md="6" className="mt-1">
                                            <select
                                                onChange={(e) => setState(e.target.value)}
                                                id="formrow-InputState"
                                                className="form-control arrows"
                                            >
                                                <option name="state" value="">State</option>
                                                <option value='AL'>AL</option>
                                                <option value='AK'>AK</option>
                                                <option value='AZ'>AZ</option>
                                                <option value='AR'>AR</option>
                                                <option value='CA'>CA</option>
                                                <option value='CO'>CO</option>
                                                <option value='CT'>CT</option>
                                                <option value='DE'>DE</option>
                                                <option value='DC'>DC</option>
                                                <option value='FL'>FL</option>
                                                <option value='GA'>GA</option>
                                                <option value='HI'>HI</option>
                                                <option value='ID'>ID</option>
                                                <option value='IL'>IL</option>
                                                <option value='IN'>IN</option>
                                                <option value='IA'>IA</option>
                                                <option value='KS'>KS</option>
                                                <option value='KY'>KY</option>
                                                <option value='LA'>LA</option>
                                                <option value='ME'>ME</option>
                                                <option value='MD'>MD</option>
                                                <option value='MA'>MA</option>
                                                <option value='MI'>MI</option>
                                                <option value='MN'>MN</option>
                                                <option value='MS'>MS</option>
                                                <option value='MO'>MO</option>
                                                <option value='MT'>MT</option>
                                                <option value='NE'>NE</option>
                                                <option value='NV'>NV</option>
                                                <option value='NH'>NH</option>
                                                <option value='NJ'>NJ</option>
                                                <option value='NM'>NM</option>
                                                <option value='NY'>NY</option>
                                                <option value='NC'>NC</option>
                                                <option value='ND'>ND</option>
                                                <option value='OH'>OH</option>
                                                <option value='OK'>OK</option>
                                                <option value='OR'>OR</option>
                                                <option value='PA'>PA</option>
                                                <option value='PR'>PR</option>
                                                <option value='RI'>RI</option>
                                                <option value='SC'>SC</option>
                                                <option value='SD'>SD</option>
                                                <option value='TN'>TN</option>
                                                <option value='TX'>TX</option>
                                                <option value='UT'>UT</option>
                                                <option value='VT'>VT</option>
                                                <option value='VA'>VA</option>
                                                <option value='VI'>VI</option>
                                                <option value='WA'>WA</option>
                                                <option value='WV'>WV</option>
                                                <option value='WI'>WI</option>
                                                <option value='WY'>WY</option>
                                            </select>
                                        </Col>
                                        <Col lg='2' md="6" className="mt-1">
                                            <select
                                                onChange={(e) => setClassification(e.target.value)}
                                                id="formrow-InputState"
                                                className="form-control arrows"
                                            >
                                                <option name="operating" value="">Classification</option>
                                                <option name="operating" value="operating">Operating</option>
                                                <option name="financing" value="financing">Financing</option>
                                                <option name="both" value="both">Both</option>
                                            </select>
                                        </Col>
                                        <Col lg='2' md="6" className="mt-1">
                                            <select 
                                                className="form-control arrows" 
                                                id='currency'
                                                onChange={(e)=> setCurrency(e.target.value)}
                                                value={currency}
                                            >
                                                <option name="currency" value="">Currency</option>
                                                {
                                                    currencyData.map(opt => <option key={opt._id} value={opt._id}>{opt.code}</option> )
                                                }
                                            </select>
                                        </Col>
                                        <Col lg='2' md="6" className="mt-1">
                                            <button className="btn btn-lbusers" onClick={getLeaseData} disabled={loading} >
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                Filter Leases
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Row>
                                    {
                                        loading ?
                                            <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                        : leaseData.length < 1 ? 
                                            <div className="text-center mt-4">No data found</div> 
                                        :
                                        <>
                                            <Row className="mt-4">
                                                <Col>
                                                    <input id="fasb_leases" type="radio" className="form-check-input" checked={fasbSelected} onClick={((e)=>checkWithStandard(e, 'FASB ASC 842'))} />
                                                    <label
                                                        className="form-check-label ms-2"
                                                        htmlFor="fasb_leases"
                                                    >
                                                        Select FASB ASC 842
                                                    </label>
                                                    <input id="gasb_leases" type="radio" className="form-check-input ms-4" checked={gasbSelected} onClick={((e)=>checkWithStandard(e, 'GASB 87'))} />
                                                    <label
                                                        className="form-check-label ms-2"
                                                        htmlFor="gasb_leases"
                                                    >
                                                        Select GASB 87
                                                    </label>
                                                    <input id="ifrs_leases" type="radio" className="form-check-input ms-4" checked={ifrsSelected} onClick={((e)=>checkWithStandard(e, 'IFRS 16'))} />
                                                    <label
                                                        className="form-check-label ms-2"
                                                        htmlFor="ifrs_leases"
                                                    >
                                                        Select IFRS 16
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                {leaseData.map((lease) =>
                                                    <Col md="4" lg="4" xl="4" xxl="4" key={lease._id}>
                                                        <div className="d-flex mt-4">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={lease._id}
                                                                    checked={showCheck(lease)}
                                                                    onClick={e => {checkArr(lease)}}
                                                                />
                                                            </div>
                                                            <label
                                                                className="form-check-label ms-2"
                                                                htmlFor={lease._id}
                                                            >
                                                                {lease.name+" "}({lease && lease.currency && lease.currency.code ? lease.currency.code : 'USD'})
                                                            </label>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    }
                                </Row>
                            </Row>
                        </CardBody>
                    </Card>
                </CardBody>
                <div className="d-flex flex-wrap gap-2 pb-3" style={{ flexDirection: 'row-reverse', marginRight: '18px' }}>
                {
                diffCurrency  ?
                    <span className="d-inline-block" dataToggle="tooltip" dataPlacement="top" title="Please select leases with same currency">
                        <Button
                            color="info"
                            className="btn btn-lbusers "
                            size='2'
                            onClick={() => {
                                setDatePopupOpen(true)
                            }}
                            disabled={!selectedLeases || selectedLeases.length < 1 || diffCurrency || loading}
                        >
                            {type === 'disclosures' ? 'VIEW DISCLOSURES' 
                            :type === 'journal_entries'? 'VIEW JOURNAL ENTRIES'
                            :type === 'reports'? 'ACCOUNTING SNAPSHOT'
                            :''
                            }
                        </Button>
                    </span>
                    : 
                    <Button
                        color="info"
                        className="btn btn-lbusers "
                        size='2'
                        onClick={() => {
                            setDatePopupOpen(true)
                        }}
                        disabled={!selectedLeases || selectedLeases.length < 1 || diffCurrency || loading}
                    >
                        {type === 'disclosures' ? 'VIEW DISCLOSURES' 
                        :type === 'journal_entries'? 'VIEW JOURNAL ENTRIES'
                        :type === 'reports'? 'ACCOUNTING SNAPSHOT'
                        :''
                        }
                    </Button>
                }{
                    type === 'reports' &&
                    <Button
                        color="info"
                        className="btn btn-lbusers "
                        size='2'
                        onClick={() => {
                            if(userData && userData.checked_browser_setting){
                                downloadTemplate(true)
                            }else{
                                setBrowserSetting(true)
                            }
                        }}
                        disabled={!selectedLeases || selectedLeases.length < 1 || diffCurrency || loading}
                    >
                        DOWNLOAD LEASE INPUTS
                    </Button>
                }
                </div>
                {
                    recentSearches && recentSearches.length > 0 && 
                    <CardBody>
                        <hr/>
                        <div>
                            <h5>Recent Searches</h5>
                            {
                                recentSearches.map(v => {
                                    return <div key={v._id} style={{width:'100%'}} className="mt-3">
                                        <Alert
                                            color={v.is_processing ? "warning" : "success"}
                                            className="alert-dismissible fade show text-black"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => getSearchResult(v._id, 'delete')}
                                        >
                                            {v.is_processing ? <b style={{fontSize: '18px'}}>{v.type== 'journal_entries'?'JOURNAL ENTRIES': v.type.toUpperCase()} FOR THE FOLLOWING PARAMETERS ARE BEING FETCHED:</b> : <b style={{fontSize: '18px'}}>{v.type== 'journal_entries' ? 'JOURNAL ENTRIES' : v.type.toUpperCase()} FOR THE FOLLOWING PARAMETERS ARE READY:</b>} <br/><br/>
                                            {
                                                v.type == 'reports'?
                                                <><b>Date:</b> {moment(v.date).utc().format('MM/DD/YYYY')}, <br/></>
                                                :
                                                <><b>Date range:</b> {moment(v.startDate).utc().format('MM/DD/YYYY')}-{moment(v.endDate).utc().format('MM/DD/YYYY')}, <br/></>
                                            }
                                            {
                                                v.type == 'journal_entries' && <><b>Type:</b> {v.je_type.toUpperCase()} <br/></>
                                            }
                                            <b>Clients:</b> {v.clients && v.clients.length > 1 ? v.clients[0].client+", "+v.clients[1].client+(v.clients.length > 2 ? " + "+(v.clients.length - 2)+" others" : '') : v.clients && v.clients.length > 0 ? v.clients[0].client : ''} <br/>
                                            <b>Leases:</b> {v.lease_ids && v.lease_ids.length > 1 ? v.lease_ids[0].name+", "+v.lease_ids[1].name+(v.lease_ids.length > 2 ? " + "+(v.lease_ids.length - 2)+" others" : '') : v.lease_ids && v.lease_ids.length > 0 ? v.lease_ids[0].name : ''}
                                        </Alert>
                                    </div>
                                })
                            }
                        </div>
                    </CardBody>
                }
            </Card>
            <DateModal datePopupOpen={datePopupOpen} setDatePopupOpen={setDatePopupOpen} type={type} selectedLeases={selectedLeases} handleClick={prop.handleClick} jeType={jeType} setJEType={setJEType} handleChangeJeType={handleChangeJeType} dateType={dateType} setDateType={setDateType} handleChangeType={handleChangeType}/>
            <BrowserSettingPopup settingOpen={browserSetting} toggleSetting={toggleBrowserSetting} setSettingOpen={setBrowserSetting} downloadTemplate={downloadTemplate}/>
        </React.Fragment>
    )
}

Filter.propTypes = {
    handleClick: PropTypes.any
}
export default Filter