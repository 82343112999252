import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";
import { Container, Row, Col, Card, CardTitle, CardBody,Spinner, Button } from "reactstrap"
import PieChart from './piechart'
import Breadcrumb from "../../components/Common/Breadcrumb";
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import moment from "moment";
import { AuthContext } from "context/authContext";
import {commaSeperatedNumberWithoutDecimal} from "helpers/stringHelper"
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Dashboard = () => {
  const { userData } = useContext(AuthContext);
  const [usersData, setUsersData] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [leaseSummary, setLeaseSummary] = useState([]);
  const [leaseData, setLeaseData] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientData, setSelectedClientData] = useState({});
  const [currentCost, setCurrentCost] = useState(0)
  const [clientCost, setClientCost]=useState(0)

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>{tableData && tableData[dataIndex] && tableData[dataIndex].name || 'NA'}</div>
        )
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>{tableData && tableData[dataIndex] && tableData[dataIndex].email || 'NA'}</div>
        )
      }
    },
    {
      name: "type",
      label: "Role",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>
            {`${tableData && tableData[dataIndex] && tableData[dataIndex].type === 'user' ? `${customerData && customerData.company ? customerData.company.toUpperCase() : ''} `: ''}${tableData && tableData[dataIndex] && tableData[dataIndex].type ? tableData[dataIndex].type.replace("_", " ").toUpperCase() : 'NA'}` || 'NA'}
          </div>
        ),
      }
    },
    {
      name: "no_of_clients",
      label: "No of clients ",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>{tableData && tableData[dataIndex] && tableData[dataIndex].no_of_clients ? commaSeperatedNumberWithoutDecimal(tableData[dataIndex].no_of_clients) : 0}</div>
        )
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>
            {
              (tableData && tableData[dataIndex] && tableData[dataIndex].email == userData.email) || (tableData && tableData[dataIndex] && tableData[dataIndex].email == userData.user_email) ?
              <div className={`text-${tableData && tableData[dataIndex] && tableData[dataIndex].is_active ? 'success' : 'warning'} ms-1`}
                style={{ border: 'none', width: 'auto', fontWeight: 'bold' }}
              >
                {tableData && tableData[dataIndex] && tableData[dataIndex].is_active ? 'ACTIVE' : 'DISABLED'}
              </div>
              :
              <select
                id="formrow-InputState"
                className={`drp text-${tableData && tableData[dataIndex] && tableData[dataIndex].is_active ? 'success' : 'warning'}`}
                style={{ border: 'none', width: 'auto', fontWeight: 'bold' }}
                onChange={(e) => handleStatusChange(e, tableData && tableData[dataIndex] && tableData[dataIndex])}
              >
                <option name="active" value={true} selected={tableData && tableData[dataIndex] && tableData[dataIndex].is_active}>ACTIVE</option>
                <option name="disable" value={false} selected={tableData && tableData[dataIndex] && !tableData[dataIndex].is_active}>DISABLED</option>
              </select>
            }
          </div>
        ),
      }
    },
    {
      name: "last_login",
      label: "Last Login ",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>{tableData && tableData[dataIndex] && tableData[dataIndex].last_login ? moment(tableData[dataIndex].last_login).format('MM/DD/YYYY HH:mm') : 'NA'}</div>
        ),
      }
    },
  ]

  const handleStatusChange = async(e, user) =>{
    setLoading(true)
    setUsersData([])
    try {
      const {data} = await axios.post(`${AppConfig.baseUrl}/users/change_status`, {id: user._id, is_active: e.target.value},{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        toast.success("User status changed successfully");
        getUserData()
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  }

  const getUserData = async() =>{
    setLoading(true);
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/users/list_user`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setUsersData(data.usersData);
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const getCustomerData = async() =>{
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/customer/get_lease_summary?id=${userData.id}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setLeaseSummary(data.leaseDataByClient);
        setLeaseData(data.leaseData);
        setPricing(data.pricingDetail);
        setCustomerData(data.customerDetails && data.customerDetails.customer_id ? data.customerDetails.customer_id : {});
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  }

  useEffect(() => {
    if(userData.info_id){
      getCustomerData();
      getUserData();
    }
  }, [userData])

  useEffect(() => {
    if(Object.keys(customerData).length > 0 && usersData.length > 0){
      setTableData(usersData)
    }
  },[customerData, usersData])

  useEffect(() => {
    const result = leaseSummary.filter(i => i.clientName == selectedClient);
    setSelectedClientData(result[0] || {})
    if (result && result[0] && pricing && pricing[0]){
      const inactive_leases = result[0].expiredLeases
      const active_leases = result[0].activeLeases
      const terminated_leases = result[0].terminatedLeases
      const drafted_leases = result[0].draftedLeases
      const noOfLeases = active_leases + ((terminated_leases + inactive_leases + drafted_leases)/4)
      const discountData = pricing.filter(v => v.min_leases === 0);
      let amount = (discountData[0].yearly_price / 12) * noOfLeases;
      if(noOfLeases > 50){
          const disc = pricing.filter(v => v.min_leases === 51);
          const multiple = noOfLeases > 100 ? 50 : noOfLeases - 50;
          amount += (disc[0].yearly_price / 12) * multiple;
      }
      if(noOfLeases > 100){
        const disc1 = pricing.filter(v => v.min_leases === 101);
          const multiple = noOfLeases > 250 ? 150 : noOfLeases - 100;
          amount += (disc1[0].yearly_price / 12) * multiple;
      }
      if(noOfLeases > 250){
        const disc2 = pricing.filter(v => v.min_leases === 251);
          const multiple = noOfLeases > 500 ? 250 : noOfLeases - 250;
          amount += (disc2[0].yearly_price / 12) * multiple;
      }
      if(noOfLeases > 500){
        const disc3 = pricing.filter(v => v.min_leases === 501);
          const multiple = noOfLeases - 500;
          amount += (disc3[0].yearly_price / 12) * multiple;
      }
      setClientCost(amount.toFixed(2))
    }else{
      setClientCost(0)
    }
  },[selectedClient])
  
  useEffect(()=>{
    if (leaseData && leaseData[0] && pricing && pricing[0]){
      const inactive_leases = leaseData[0].expiredLeases
      const active_leases = leaseData[0].activeLeases
      const terminated_leases = leaseData[0].terminatedLeases
      const drafted_leases = leaseData[0].draftedLeases
      const noOfLeases = active_leases + ((terminated_leases + inactive_leases + drafted_leases)/4)
      const discountData = pricing.filter(v => v.min_leases === 0);
      let amount = (discountData[0].yearly_price / 12) * 50;
      if(noOfLeases > 50){
          const disc = pricing.filter(v => v.min_leases === 51);
          const multiple = noOfLeases > 100 ? 50 : noOfLeases - 50;
          amount += (disc[0].yearly_price / 12) * multiple;
      }
      if(noOfLeases > 100){
        const disc1 = pricing.filter(v => v.min_leases === 101);
          const multiple = noOfLeases > 250 ? 150 : noOfLeases - 100;
          amount += (disc1[0].yearly_price / 12) * multiple;
      }
      if(noOfLeases > 250){
        const disc2 = pricing.filter(v => v.min_leases === 251);
          const multiple = noOfLeases > 500 ? 250 : noOfLeases - 250;
          amount += (disc2[0].yearly_price / 12) * multiple;
      }
      if(noOfLeases > 500){
        const disc3 = pricing.filter(v => v.min_leases === 501);
          const multiple = noOfLeases - 500;
          amount += (disc3[0].yearly_price / 12) * multiple;
      }
      setCurrentCost(amount.toFixed(2))
    }else{
      setCurrentCost(0)
    }
  },[leaseData,pricing])

  const tableToCSV = () => {
    let csv_data = [];
    csv_data.push(',Active,Terminated (1/4th of an Active Leases),Expired (1/4th of an Active Leases),Drafted (1/4th of an Active Leases),Total')
    for (const s of leaseSummary) {
      csv_data.push(`\"${s.clientName}\",${s.activeLeases},${s.terminatedLeases},${s.expiredLeases},${s.draftedLeases},${s.activeLeases + (s.terminatedLeases > 0 ? s.terminatedLeases/4 : 0) + (s.expiredLeases > 0 ? s.expiredLeases/4 : 0) + (s.draftedLeases > 0 ? s.draftedLeases/4 : 0)}`)
    }
    csv_data.push(`,,,,Total Leases,${leaseData && leaseData[0] ?(leaseData[0].activeLeases + (leaseData[0].terminatedLeases > 0 ? leaseData[0].terminatedLeases/4 : 0) + (leaseData[0].expiredLeases > 0 ? leaseData[0].expiredLeases/4 : 0) + (leaseData[0].draftedLeases > 0 ? leaseData[0].draftedLeases/4 : 0)) : 0}`)
    csv_data = csv_data.join('\n');
    downloadCSVFile(csv_data);
  }

  const downloadCSVFile = (csv_data) => {
    let CSVFile = new Blob([csv_data], {
      type: "text/csv"
    });
    var temp_link = document.createElement('a');
    temp_link.download = "lease_summary.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  const rowClasses = (row, rowIndex) => {
    let classes = null;
    if (row.email == userData.email || row.email == userData.user_email) {
      classes = 'row-grey';
    }
  
    return classes;
  };
  
  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseJava | Dashboard</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Dashboard" />
          {
            loading ? 
              <div style={{textAlign:'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
            :
            <>
              <Row>
                <Col xl={7}>
                  <Card style={{height:'380px'}}>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <div className="me-2">
                          <h5 className="card-title">Lease Summary</h5>
                        </div>
                        <Button className="btn btn-lbusers btn-md mb-2" onClick={tableToCSV} >
                          {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          Export CSV
                        </Button>
                      </div>
                      <div className="table-responsive"  style={{height:'300px'}}>
                        <table className="table align-middle table-nowrap mb-0">
                          <thead style={{backgroundColor:'aliceblue'}}>
                            <tr>
                              <th scope="col" colSpan={1}></th>
                              <th scope="col" style={{paddingBottom:'26px'}}>Active</th>
                              <th scope="col" style={{paddingTop:'18px'}}>Terminated<h6 style={{fontSize:'10px',marginTop:'5px'}}>(1/4<sup>th</sup> of an Active Leases)</h6></th>
                              <th scope="col">Expired<h6 style={{fontSize:'10px',marginTop:'5px'}}>(1/4<sup>th</sup> of an Active Leases)</h6></th>
                              <th scope="col">Drafted<h6 style={{fontSize:'10px',marginTop:'5px'}}>(1/4<sup>th</sup> of an Active Leases)</h6></th>
                              <th scope="col" style={{paddingBottom:'26px'}}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaseSummary.map((i, key) => (
                              <tr key={key}>
                                <td>
                                  {i.clientName}
                                </td>
                                <td>
                                  {i.activeLeases}
                                </td>
                                <td>
                                  {i.terminatedLeases}
                                </td>
                                <td>
                                  {i.expiredLeases}
                                </td>
                                <td>
                                  {i.draftedLeases}
                                </td>
                                <td>
                                  {i.activeLeases + (i.terminatedLeases > 0 ? i.terminatedLeases/4 : 0) + (i.expiredLeases > 0 ? i.expiredLeases/4 : 0) + (i.draftedLeases > 0 ? i.draftedLeases/4 : 0)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot style={{position:'sticky',bottom :0,backgroundColor:'aliceblue'}}>
                            <tr>
                            <td style={{textAlign:'end'}} colSpan={5}><h6 style={{marginTop:'10px'}}>Total Leases</h6></td>
                            <td>{leaseData && leaseData[0] ?(leaseData[0].activeLeases + (leaseData[0].terminatedLeases > 0 ? leaseData[0].terminatedLeases/4 : 0) + (leaseData[0].expiredLeases > 0 ? leaseData[0].expiredLeases/4 : 0) + (leaseData[0].draftedLeases > 0 ? leaseData[0].draftedLeases/4 : 0)) : 0} </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={5}>
                  <Card style={{height:'380px'}}>
                    <CardBody>
                        <div className="me-2">
                          <h5 className="card-title mb-4">Billing Summary</h5>
                        </div>
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <tbody>
                              <tr>
                                <td><h5 className="font-size-13 text-truncate mb-1">Account discount</h5></td>
                                <td><p className="text-muted align-middle me-1 mt-1">{pricing && pricing[0] && pricing[0].discount_per ? pricing[0].discount_per + "%" : ""}</p></td>
                              </tr>
                              <tr>
                                <td><h5 className="font-size-13 text-truncate mb-1">Projected Invoice Amount [for the current month]</h5></td>
                                <td><p className="text-muted align-middle me-1 mt-1">${currentCost}</p></td>
                              </tr>
                              <tr>
                                <td><h5 className="font-size-13 text-truncate mb-1">Last Month invoice Amount</h5></td>
                                <td><p className="text-muted align-middle me-1 mt-1">{customerData.charge_amount ? "$" + customerData.charge_amount.toFixed(2) : 0}</p></td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl={7}>
                  <Card style={{height:'310px',overflowX: 'auto'}}>
                    <CardBody>
                      <CardTitle className="mb-2">Leases by Client</CardTitle>
                      <PieChart leaseSummary={leaseSummary}/>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={5}>
                  <Card style={{height:'310px'}}>
                    <CardBody>
                      <div className="me-2">
                        <h5 className="card-title" style={{display:'inline-block'}}>Cost breakdown by client</h5>
                        <select  onChange={e=>setSelectedClient(e.target.value)} className="form-select form-select-sm" style={{width:'145px',display:'inline-block',float:'right'}}>
                          <option value="">Please select client</option>
                        {leaseSummary.map(i =>
                          <option value={i.clientName} key={i.clientName}>{i.clientName}</option>
                        )}
                        </select>
                      </div>
                      <div className="table-responsive mt-1">
                        <table className="table align-middle table-nowrap mb-0">
                        <tbody>
                              <tr>
                                <td><h5 className="font-size-13 text-truncate mb-1">Total Leases</h5></td>
                                <td><p className="text-muted align-middle me-1 mt-1">{(selectedClientData.activeLeases + (selectedClientData.terminatedLeases > 0 ? (selectedClientData.terminatedLeases)/4 : 0) + (selectedClientData.expiredLeases > 0 ? (selectedClientData.expiredLeases)/4 : 0) + (selectedClientData.draftedLeases > 0 ? (selectedClientData.draftedLeases)/4 : 0)) || 0}</p></td>
                              </tr>
                              <tr>
                                <td><h5 className="font-size-13 text-truncate mb-1">Projected cost for current month</h5></td>
                                <td><p className="text-muted align-middle me-1 mt-1">${clientCost}</p></td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                        <MUIDataTable
                          title="USERS"
                          data={tableData}
                          columns={columns}
                          options={{ 
                            rowsPerPage:20, 
                            print:false, 
                            viewColumns:false,
                            filter:false, 
                            selectableRows:"none", 
                            search:false, 
                            rowsPerPageOptions:[], 
                            responsive:'scroll',
                            textLabels: {
                              body: {
                                noMatch: loading || tableData.length != usersData.length ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                              },
                              viewColumns: {
                                title: "",
                              },
                            } ,
                            downloadOptions:{
                              filename:'users.csv'
                            },
                            onDownload:( buildHead, buildBody, columns, data) =>{
                              try{
                                const formattedData = []
                                for(const [index,row] of data.entries()){
                                  const newRow = [ ...row.data ];
                                  newRow[2] = `${newRow[2] === 'user' ? `${customerData && customerData.company ? customerData.company.toUpperCase() : ''} `: ''}${newRow[2] ? newRow[2].replace("_", " ").toUpperCase() : 'NA'}` || 'NA'
                                  newRow[4] = tableData && tableData[index] && tableData[index].is_active ? 'ACTIVE' : 'DISABLED'
                                  newRow[5] = newRow[5] ? moment(newRow[5]).format('MM/DD/YYYY HH:mm') : 'NA'
                                  formattedData.push({ ...row, data: newRow })
                                }
                                const csv = buildHead(columns) + buildBody(formattedData);
                                return csv
                              }catch(err){
                                console.log(err)
                                return buildHead(columns) + buildBody(data);
                              }                              
                            }
                          }}
                        />
                      </ThemeProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </Container>
      </div>
    </Layout>
  )
}

export default Dashboard;
