import React,{useEffect, useState, useContext } from "react"
import { CardBody,Spinner, Row, Col, Input } from "reactstrap"
import EditClient from "./editClient";
import DeleteClient from "./deleteClient";
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import ButtonDropdownClient from "../../components/client/ButtonDropdown";
import { AuthContext } from "context/authContext";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const ActiveClients = (prop) => {
  const { getClientsData, loading, clientData, setClientsData ,originalData, leaseByClient, setLeaseByClient, getCustomerData} = prop;
  const [selectedClient, setSelectedClient] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
	const { userData } = useContext(AuthContext);
  const [search, setSearch] = useState("");

  const columns = [
      {
        name: "client",
        label: "Client",
        options: {
        setCellProps: () => ({style: {minWidth: '100px', height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite:(dataIndex)=>{
          return  <>
          {clientData && clientData[dataIndex] && !clientData[dataIndex].logo ? (
              <div style={{display:'flex', flexDirection:'row'}}>
                  <div className="avatar-sm">
                      <div className="avatar-title rounded-circle">
                          {clientData && clientData[dataIndex] && clientData[dataIndex].client.charAt(0)}
                      </div>
                  </div>
                  <div className="client">
                      <div>{clientData && clientData[dataIndex] && clientData[dataIndex].client}</div>
                  </div>
              </div>
          ) : (
              <div style={{display:'flex', flexDirection:'row'}}>
                  <div className="avatar-sm">
                      <img
                          className="rounded-circle avatar-sm"
                          src={clientData && clientData[dataIndex] && clientData[dataIndex].logo}
                          alt=""
                      />
                  </div>
                  <div className="client">
                      <div>{clientData && clientData[dataIndex] && clientData[dataIndex].client}</div>
                  </div>
              </div>
          )}
            </>
          },
      }
      },
      {
        name: "contact_person",
        label: "Contact",
        options: {
        customBodyRender:(value)=> (<div className="table-data-margin">{value || 'NA'}</div>),
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
        customBodyRender:(value)=> (<div className="table-data-margin">{value || 'NA'}</div>),
        }
      },
      {
        name: "no_of_leases",
        label: "# Of Leases",
        options: {
          customBodyRenderLite:(dataIndex)=> {
            const clientLeases = leaseByClient && leaseByClient[0] ? leaseByClient.filter(v => String(v._id) == String(clientData && clientData[dataIndex] && clientData[dataIndex]._id)) : []
            const no_of_leases = clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) + ((Number(clientLeases[0].terminatedLeases || 0) + Number(clientLeases[0].expiredLeases || 0) + Number(clientLeases[0].draftedLeases || 0))/4) : 0
            return <div 
              className="table-data-margin"
              tabIndex="0" dataToggle="tooltip" 
              title={`Active Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) : 0} \nTerminated Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].terminatedLeases || 0) : 0} \nInactive Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].expiredLeases || 0) : 0} \nDrafted Leases: ${clientLeases && clientLeases [0] ? Number(clientLeases[0].draftedLeases || 0) : 0}`}
            >
              {no_of_leases}
            </div>
          },
        }
      },
      {
        name: "added_by",
        label: "Added by",
        options: {
          customBodyRenderLite:(dataIndex)=>(
            <div className="table-data-margin">{clientData && clientData[dataIndex] && clientData[dataIndex].fromcustomer ? clientData[dataIndex].fromcustomer.admin_name : clientData && clientData[dataIndex] && clientData[dataIndex].fromuser ? clientData[dataIndex].fromuser.name : ''}</div>
          ),
        }
      },
      {
        name: "client_type",
        label: "Client type",
        options: {
          customBodyRender:(value)=>(<div className="table-data-margin">{value || 'lessee'}</div>)
        }
      },
      {
        name: "menu",
        label: "Action",
        options: {
          customBodyRenderLite:(dataIndex)=>(
            <div className="mt-2">
              <ButtonDropdownClient setEditOpen={setEditOpen} setDeleteOpen={setDeleteOpen} client={clientData && clientData[dataIndex]} setSelectedClient={setSelectedClient} type="client_list"/>
            </div>
          ),
        }
      },
  ]

  useEffect(() => {
    if(userData.info_id){
        getCustomerData()
    }
  },[userData])

  // toggle edit account modal
  const toggleEdit = () => {
    setEditOpen(!editOpen);
  }
  // toggle delete account modal
  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
  }

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.client.match(new RegExp(value,"i")))
    setClientsData(data)
  }

  return (
    <React.Fragment>
       <CardBody>
            <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
              <MUIDataTable
                title={
                  <Row className="row">
                    <Col md="6" className="search-p">
                      <div className="me-2 mb-3 mt-3">
                        <div className="position-relative sw">
                        <Input
                            placeholder="Search"
                            type="text"
                            value={search}
                            onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                        />
                        </div>
                      </div>
                    </Col>
                  </Row>
                }
                data={clientData}
                columns={columns}
                options={{ 
                  rowsPerPage:20, 
                  print:false, 
                  download:true, 
                  filter:false, 
                  selectableRows:"none", 
                  search:false, 
                  rowsPerPageOptions:[], 
                  responsive:'scroll',
                  textLabels: {
                    body: {
                      noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                    },
                    viewColumns: {
                      title: "",
                    },
                  } ,
                  downloadOptions:{
                    filename:'activeClients.csv'
                  },
                  onDownload:( buildHead, buildBody, columns, data) =>{
                    try{
                      const formattedData = []
                      for(const [index,row] of data.entries()){
                        const newRow = [ ...row.data ];
                        newRow[2] = newRow[2] ? newRow[2] : 'NA'
                        const clientLeases = leaseByClient && leaseByClient[0] ? leaseByClient.filter(v => String(v._id) == String(clientData && clientData[index] && clientData[index]._id)) : []
                        newRow[3] = clientLeases && clientLeases [0] ? Number(clientLeases[0].activeLeases || 0) + ((Number(clientLeases[0].terminatedLeases || 0) + Number(clientLeases[0].expiredLeases || 0) + Number(clientLeases[0].draftedLeases || 0))/4) : 0
                        newRow[4] = clientData && clientData[index] && clientData[index].fromcustomer ? clientData[index].fromcustomer.admin_name : clientData && clientData[index] && clientData[index].fromuser ? clientData[index].fromuser.name : ''
                        formattedData.push({ ...row, data: newRow })
                      }
                      const csv = buildHead(columns) + buildBody(formattedData);
                      return csv
                    }catch(err){
                      console.log(err)
                      return buildHead(columns) + buildBody(data);
                    }                              
                  }
                }}
              />
            </ThemeProvider>
        </CardBody>
        <EditClient editOpen={editOpen} toggleEdit={toggleEdit} setEditOpen={setEditOpen} client={selectedClient} getClientsData={getClientsData} setSelectedClient={setSelectedClient}/>
        <DeleteClient deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} client={selectedClient} getClientsData={getClientsData} setSelectedClient={setSelectedClient} />
    </React.Fragment>
  )
}

export default ActiveClients
