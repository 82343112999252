import {Helmet} from "react-helmet";
import React, { useEffect, useContext, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    Form,
    FormGroup,
    InputGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { AuthContext } from "context/authContext"
import axios from "axios"
import AppConfig from "constants/config"
import toast from "react-hot-toast";
import Layout from "components/VerticalLayout"

const Settings = () => {
    const { userData, reloadUserData } = useContext(AuthContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            is_2fa_active: false,
            _2fa_frequency: '',
        },
        onSubmit: async(values) => {
            setLoading(true);
            try {
                if (values._2fa_frequency && Number(values._2fa_frequency) < 0){
                    return formik.setFieldError("_2fa_frequency", "Please enter valid frequency")
                }
                values.id = userData.id;
                values.name = userData.name;
                values.email = userData.email;
                const { data } = await axios.post( `${AppConfig.baseUrl}/customer/edit_profile`, values, {
                    headers: { token: localStorage.getItem("token")},
                });
                if (!data.error) {
                    toast.success("Account updated successfully")
                    reloadUserData()
                    navigate(`/${localStorage.getItem('company')}/dashboard`)
                } else {
                    throw new Error(data.title)
                }
            } catch (error) {
                toast.error(error.message || "Something went wrong")
            }
            setLoading(false);
        }
    });

    useEffect(() => {
        if(userData){
            formik.setValues({
                is_2fa_active: userData.is_2fa_active,
                _2fa_frequency: userData._2fa_frequency,
            })
        }
    },[userData])

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Settings</title>
                </Helmet>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="LeaseJava" breadcrumbItem="SETTINGS" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            formik.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup className="form-group">
                                                    <div className="d-flex">
                                                        <Label className="form-label factLabel">2-factor authentication </Label>
                                                        <div className="form-check form-switch form-switch-lg mb-3">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="is_2fa_active"
                                                                onChange={(e) => {
                                                                    if(!e.target.checked){
                                                                        formik.setFieldValue("_2fa_frequency", "")
                                                                    }
                                                                    formik.handleChange(e)
                                                                }}
                                                                disabled={loading}
                                                                onBlur={formik.handleBlur}
                                                                name="is_2fa_active"
                                                                checked={formik.values.is_2fa_active}
                                                            />
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <Row>
                                                    <Col lg="6" md="6">
                                                        <FormGroup className="form-group">
                                                            <Label className="form-label">Frequency</Label>
                                                            <InputGroup>
                                                                <Input
                                                                    name="_2fa_frequency"
                                                                    className="form-control"
                                                                    type="number"
                                                                    min={0}
                                                                    disabled={!formik.values.is_2fa_active || loading}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values._2fa_frequency || ""}
                                                                />
                                                                <div className="input-group-text">Days</div>
                                                                {formik.touched._2fa_frequency && formik.errors._2fa_frequency && (
                                                                    <div className="text-danger">{formik.errors._2fa_frequency}</div>
                                                                )}
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="text-end" style={{marginTop:'15%'}}>
                                            <Button type="submit" className="bg-theme-color" disabled={loading} >
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                SAVE
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Settings;
